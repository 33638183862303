import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useHistory } from "react-router-dom"
import { isSafari } from 'react-device-detect'
import './article-detail.css'

const ArticleDetail = (props) => {

    const { state } = props.location
    const history = useHistory()

    const onBackClicked = () => {
        if (isSafari) {
            history.go(-1)
        } else {
            history.goBack()
        }
    }

    return (
        <div className="article-detail-container">
            <img className="article-detail-image" src={state.data && state.data.image}/>
            <FaArrowLeft onClick={() => onBackClicked()} className="article-detail-back"/>
            <div className="article-detail-container-2"></div>
            <p className="article-detail-title">{state.data && state.data.title}</p>
            <p className="article-detail-date">{state.data && state.data.time}</p>
            <div className="article-detail-content" dangerouslySetInnerHTML={ {__html: state.data && state.data.content || "" }} />
        </div>
    )
}

export default ArticleDetail
