
import { Redirect } from 'react-router-dom'
import swal from 'sweetalert'

export const url = "https://tappoint.id/backendtappoint/public/api/"
// export const url = "https://tappoint.id/backendggtouchpointdevelopmentreport/public/api/"

export const headerBearer = new Headers({
    'Authorization': 'Bearer '+ localStorage.getItem("accessToken"), 
    'Content-Type': 'application/x-www-form-urlencoded'
  })

export const headerBearerJsonRequest = new Headers({
    'Authorization': 'Bearer '+ localStorage.getItem("accessToken"),
    'Content-Type': 'application/json'
})

export const headerNoUrlEncodedBearer = new Headers({
    'Authorization': 'Bearer '+ localStorage.getItem("accessToken")
  })

export const requestOptions = (body) => {
    return {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }
}

export const getJsonResponse = (response) => {
    if (response.redirected || response.status == 302 || response.status === 401) {
        localStorage.clear()
        return swal("Unauthorized", "Token anda expired atau anda telah login di lebih dari 1 device.", ERROR)
                .then(() => {
                    window.location.href = "/login";            
                })
    } 

    if (response.status == 200) {
        return response.json()
    } else {
        return response.json().then(text => {
            throw new Error(text.message).message
        })
    }
}

export const ggAlert = (title, message, type) => {
    return swal(title, message, type)
}

export const ERROR = "error"
export const SUCCESS = "success"
export const INFO = "info"