import { FaArrowLeft, FaSearch } from 'react-icons/fa'
import { BiScan } from 'react-icons/bi'
import { useHistory } from "react-router-dom"
import { isSafari } from 'react-device-detect'
import "./navbar-search.css"
import { Tabs } from 'antd'
import { GrHistory } from 'react-icons/gr'

const NavbarSearchTab = ({ placeholder, onSearch, searchKeyword, onKeywordChanged }) => {

    const history = useHistory()
    
    const onBackClicked = () => {
        if (isSafari) {
            history.go(-1)
        } else {
            history.goBack()
        }
    }

    return (
        <div className="navbarSearchTabContainer">
            <div className="navbarSearchTitleContainer">
                <div className="navbarSearchInputContainer">
                    <FaSearch className="navbarSearchIcon2"/>
                    <input className="navbarSearchInput"
                        placeholder={placeholder}
                        onChange={(e) => onKeywordChanged && onKeywordChanged(e.target.value)}
                        value={searchKeyword && searchKeyword || ""}
                        onKeyPress={(e) => e.key === "Enter" && onSearch && onSearch(e.target.value)}/>
                </div>
            </div> 
            <a style={{background: 'none', zIndex: 10}} href='#'>
                <FaArrowLeft className="navbarSearchIcon"
                    onClick={() => onBackClicked() }/>
            </a>
        </div>
    )
}

export default NavbarSearchTab
